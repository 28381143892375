import React, {useEffect, Suspense} from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from './components/Navigation';
import Amenities from './components/Amenities';
import MainPage from './components/MainPage';
import Story from './components/Story';
import Suites from './components/Suites';
import Floorplans from './components/Floorplans';
import Neighbourhood from './components/Neighbourhood';
import Overview from './components/Overview';
import Favourites from './components/modals/Favourites/Favourites';

import { useIdleTimer } from 'react-idle-timer'
import { toggleMenu } from "./redux/actions/menuActions";
import { toggleFavorites } from "./redux/actions/menuActions";
import { loadProjectData } from './redux/actions/projectActions';

import { loadScreenSaverData } from './redux/actions/screensaverActions';
import { connect } from 'react-redux';

import './App.css';
import Screensaver from './components/Screensaver/Screensaver';

function App(props) {

  const [idle, setIdle] = React.useState(false);

  const onIdle = () => {
    // console.log('onIdle');
    setIdle(true);
  }

  const onActive = (event) => {
    // console.log('onActive');
    setIdle(false);
  }

  const idleTimer = useIdleTimer({ 
    // timeout: 1000 * 60 * 20,
    timeout: 300000,
    onIdle, 
    onActive 
  })

  var containerStyle = {
      padding: 24,
      marginLeft: 240,
      transition: 'margin 700ms',
      height: '100%'
  };

  useEffect(() => {
    props.loadProjectData();
    props.loadScreenSaverData();
  }, []);

  return (
      <div className="Background">
        {/* <React.StrictMode> */}
          <Router>
          <Suspense fallback={<div> loading... </div>}>
            {idle ?
              <>
                {props.screensaver.isLoaded ?
                  <Screensaver images={props.screensaver} active={idle}/>
                : console.log('loading screensaver....')
                }
              </>
            : null }

            <Navigation floorplans={props}/>
            <Favourites/>
          </Suspense>

            <div className="Container" style={containerStyle} tag="layout">
              <Routes>
                <Route path='/' element={<MainPage />} />
                <Route path='/overview' element={<Overview />} />
                <Route path='/amenities' element={<Amenities />} />
                <Route path='/neighbourhood' element={<Neighbourhood />} />
                <Route path='/story' element={<Story />} />
                <Route path='/suites' element={<Suites />} />
                <Route path='/floorplans' element={<Floorplans />} />
                <Route path='/floorplans/:id' element={<Floorplans />} />
              </Routes>
              
            </div>
          </Router>
        {/* </React.StrictMode> */}
      </div>
  );
}


const mapStateToProps = (state) => { 
  return { 
    project: state.project,
    toggleFavorites: state.toggleFavorites,
    screensaver: state.screensaver,
  }
}

export default connect(mapStateToProps, { loadProjectData, toggleMenu, toggleFavorites, loadScreenSaverData })(App);

import React, { useState, useCallback } from "react";
// import { Document, Page, pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js' //cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js;
// const pdfjs = require('pdfjs-dist');
// pdfjs.PDFJS.workerSrc = '/js/pdf.worker.js'
const PDFViewer = (props) => {
    const [numPages, setNumPages] = useState(null);
    console.log('PDFViewer: '+props);
    console.log(props);

    // const onDocumentLoadSuccess =  useCallback((event, { numPages }) => {

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const { pdf } = props;

    return (
        <React.Fragment>
            <div 
            className="pdf-container" 
            style={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
            }}>
                <Document
                    file={pdf}
                    options={{ workerSrc: '/pdf.worker.js' }}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                    ))}
                </Document>
            </div>
        </React.Fragment>
    )
}

export default PDFViewer;
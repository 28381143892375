import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import BuilderStoryTemplate from './BuilderStoryTemplate';
import BuilderStory from '../configs/builder_story/BuilderStory';
import configs from '../configs/config.json';
import assetBuilder from '../services/assetBuilder';

import { StoryFetchData } from '../redux/actions/storyActions';
import { connect } from 'react-redux';
import { withRouter } from "../redux/actions/withRouter";

const Story = (props) => {
    console.log('Story!');

    useEffect(() => {
        // console.log("Hello");
        props.StoryFetchData();
        document.title = props.title + ' - Story';
        console.log(props);
    }, []);

    // const { CustomBuilderStory } = configs;

    return (
        <Box sx={{ width: '100%', height: '100%'}}>
            {console.log(props.story.asset)}
        
            {/* <img style={{objectFit: 'contain' }} src={props.story.asset} alt={props.story.title}/> */}
            <img style={{width: '100%', height: '100%'}} src={assetBuilder(props.story.asset)} alt={props.story.title} loading="lazy"/>
        </Box>
    );
}

const mapStateToProps = (state) => {
    console.log(state);
    return { story: state.story }
  }
  // export default connect(mapStateToProps, { LoadAmenitiesData })(Amenities);
  export default withRouter(connect(mapStateToProps, { StoryFetchData })(Story));
import React, { useEffect } from 'react';
import Gallery from './Gallery/Gallery';

import { loadOverviewData } from '../redux/actions/overviewActions';
import { connect } from 'react-redux';
import { withRouter } from "../redux/actions/withRouter";

const Overview = (props) => {

  useEffect(() => {
    props.loadOverviewData();
    document.documentElement.style.setProperty(`--asset-${'background_image'}`, 'none');
    document.title = props.title + ' - Overview';
  }, []);

  return (
    <React.Fragment>
      {props.overview.images.length > 0 ?
        <Gallery images={props.overview.images} type={'renderings'}/>
      :  null
      }
    </React.Fragment>
  )
}

const mapStateToProps = (state) => { 
  return { 
    overview: state.overview,
  }
}

export default withRouter(connect(mapStateToProps, { loadOverviewData })(Overview));
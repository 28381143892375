import React, { useEffect, useState, useCallback } from 'react';
import { Routes, Route, useParams } from "react-router-dom";

import Container from '@mui/material/Container';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Carousel, { Modal, ModalGateway } from "react-images";
import IconButton from '@mui/material/IconButton';
import { useLocation } from "react-router-dom";
import Gallery from './Gallery/Gallery';

import { FloorplansFetch } from '../redux/actions/floorplanActions';
import { connect } from 'react-redux';
import { withRouter } from "../redux/actions/withRouter";


const Floorplans = (props) => {

    const [filter, setFilter] = useState('');
    const [filteredFloorplans, setFilteredFloorplans] = useState();
    const [gotFilter, setGotFilter] = useState(false);

    let params = useParams();
    
    document.title = props.title + ' - Floorplans';
    document.documentElement.style.setProperty('--asset-background_image', 'none');

    useEffect(() => {
        console.log('fetching with filter: '+params.id);
        // console.log(params.id);
        setFilter(params.id);
        props.FloorplansFetch(params.id);
        // setCurrentImageIndex(0);
    }, [params.id]);

    // useEffect(() => {
    //     props.LoadAmenitiesData();
    //     document.title = props.title + ' - Floorplans';
    //     document.documentElement.style.setProperty(`--asset-${'background_image'}`, 'none');
    //   }, []);

    return (
      <React.Fragment>
        {props.floorplans.images.length > 0 ?
          <Gallery images={props.floorplans.images} type={"floorplans"}/>
        :  null
        }
      </React.Fragment>
    // <div className="floorplans">
    //   {props.floorplans.isLoaded ? (
    //     <>
    //     {console.log(props.floorplans.images)}
    //     <PhotoGallery photos={props.floorplans.images}/>
    //       {/* <Gallery 
    //         // targetRowHeight={2}
    //         // limitNodeSearch={4}
    //         key="floorplans-gallery"
    //         photos={photosForGallery(props.floorplans.images)} 
    //         renderImage={imageRenderer}
    //         // direction={"column"} 
    //         // columns={3} 
    //         margin={4} 
    //         onClick={openLightbox}
    //       />
          
    //       <ModalGateway>
    //         {viewerIsOpen ? (
    //           <Modal onClose={closeLightbox}>
    //             <Carousel
    //               currentIndex={currentImageIndex}
    //               views={props.floorplans.images.map(x => ({
    //                 ...x,
    //                 width: x.width,
    //                 height: x.height,
    //                 src: x.src,
    //                 caption: x.title
    //               }))}
    //             />
    //           </Modal>
    //          ) : null
    //         }
    //       </ModalGateway> */}
    //     </>
    //   ) : null }
    // </div>
    );
}

const mapStateToProps = (state) => {
    return { floorplans: state.floorplans }
}

export default withRouter(connect(mapStateToProps, { FloorplansFetch })(Floorplans));
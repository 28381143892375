import React, { useEffect, useState, useCallback } from 'react';
import { PhotoAlbum, RenderContainer, RenderContainerProps, RenderPhoto, RenderRowContainer } from "react-photo-album";
// import { useLocation  } from "react-router-dom";
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { BookmarkRemove, BookmarkAdd } from '../generic/Bookmark';
import { connect } from 'react-redux';

import Carousel, { Modal, ModalGateway } from "react-images";
import Bookmark from '../generic/Bookmark';
import PDFViewer from '../PDFViewer/PDFViewer';

const Gallery = (props) => {

const [index, setIndex] = useState(0);
const [viewerIsOpen, setViewerIsOpen] = useState(false);
const [images, setImages] = useState([]);
// const aspectRatio = (height, width) => {
//     if (height > width) {
//         return { width: 1, height: height / width }
//     } else {
//         return { height: 1, width: width / height }
//     }
//   }
    // const location = useLocation();
    // let path = location.pathname.replace('/', '');
    const openLightbox = useCallback((event, { photo, index }) => {
        setIndex(index);
        setViewerIsOpen(true);
    }, []);
  
    const closeLightbox = () => {
        setIndex(0);
        setViewerIsOpen(false);
    }

    // const renderColumnContainer = ({ columnContainerProps, children }) => (
    //     <div {...columnContainerProps}>{children}</div>
    // );

    // const renderContainer = ({ containerProps, containerRef, children }) => (
    //     <div ref={containerRef} {...containerProps}>
    //         {console.log('containerProps')}
    //         {console.log(containerProps)}
    //         {console.log('containerRef')}
    //         {console.log(containerRef)}
    //         {console.log('children')}
    //         {console.log(children)}
    //         {children}
    //     </div>
    // );

    const renderPhoto = useCallback(
    ({ layout, layoutOptions, imageProps: { alt, style, ...restImageProps } }) => (
    <div 
        className={'photoItem'} 
        style={{
            position: 'relative',
            borderRadius: "4px",
            boxSizing: "content-box",
            alignItems: "center",
            width: style?.width,
            padding: `${layoutOptions.padding - 2}px`,
            paddingBottom: 0,
            marginBottom: 20,
            overflow: "hidden",
        }}>
        <div 
            className={'imageContainer'} 
            style={{zIndex: "2"}}>
            <IconButton sx={{ color: 'rgba(255, 255, 255, 1)' }} style={{
                position: 'absolute',
                padding: '8px',
                right: '0px',
            }}>
                {/* {console.log('layout: ')} */}
                {/* {console.log(layout)} */}
                {/* {console.log(props.images)} */}
                {/* {console.log(props.images[layout.index])} */}
                {/* {console.log(restImageProps)} */}
                
                {/* {console.log(path)} */}
                {/* <Bookmark type={path} item={props.images[index]} /> */}
                <Bookmark type={props.type} item={props.images[layout.index]} />
            {/* <BookmarkBorderIcon /> */}
            </IconButton>
            {/* {console.log(imageProps)} */}
            <img 
                alt={alt} 
                style={{ 
                    ...style, 
                    width: "100%", 
                    padding: 0,
                    marginBottom: 0,
                }} {...restImageProps} 
            />
            {/* <div
                style={{
                    position: 'relative',
                    bottom: '0px',
                    width: '100%',
                    // paddingTop: "8px",
                    // paddingBottom: "8px",
                    // overflow: "visible",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    color: "#fff",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
            >
                {restImageProps.title}
            </div> */}
            <ImageListItemBar 
                style={{
                    zIndex: 2,
                    position: 'absolute',
                    // top: '0%',
                    bottom: '0%',
                    // width: '100%',
                    // top: '50%',
                    // left: '50%',
                    // transform: 'translate(-50%, -50%)'
                }}
                title={restImageProps.title}
            />
        </div>

    </div>
    ));


    return (

        <React.Fragment>
            {props.images.length > 0 ? (
            <>
            <PhotoAlbum 
                // renderContainer={renderContainer}
                // renderColumnContainer={renderColumnContainer}
                // componentsProps={{ containerProps: { style: { marginBottom: "20px" } } }}
                renderPhoto={renderPhoto}
                photos={props.images} 
                layout={'masonry'}
                columns={3}
                onClick={(event, photo, index) => { setIndex(index); setViewerIsOpen(true); }}        
                targetRowHeight={150}
            />
                <ModalGateway>
                {viewerIsOpen ?
                    <Modal onClose={closeLightbox}>
                    {props.images[index].isPDF ? 
                    <div className="all-page-container">
                        <PDFViewer pdf={props.images[index].img} />
                    </div>
                    :
                        <Carousel
                            currentIndex={index}
                            views={props.images.map(x => ({
                            ...x,
                            width: x.width,
                            height: x.height,
                            src: x.img,
                            caption: x.title
                            }))}
                            styles={{
                                header: base => ({
                                    ...base,
                                    
                                }),
                            }}
                        />
                    }
                    </Modal>
                : null
                }
                </ModalGateway>
            </>
            ) : null }
        </React.Fragment>
    )
}

export default Gallery;
// const mapStateToProps = state => { return { remove: false } };
// export default connect(null, { BookmarkRemove, BookmarkAdd })(Gallery);
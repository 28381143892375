import ACTION_TYPES from '../actionTypes.json';
import apiService from '../../services/apiService';
import assetBuilder from '../../services/assetBuilder';

export const StoryFetchData = () => async(dispatch) => {
  const storyData = await apiService.getStoryData();
  // console.log(storyData);

  // const data = storyData.map(o => {
  //   asset: assetBuilder(o.asset)
  //   return { data }
  // });

  // const data = storyData.map(o => {

  //   return {
  //     id: o.id,
  //     title: o.title,
  //     logo: assetBuilder(o.logo),
  //     description: o.description,
  //     website_url: o.website_url,
  //     url: o.url,
  //     asset: assetBuilder(o.asset),
  //   }
  // });

  // console.log('images');
  // console.log(images);

  // if (storyData.asset != null){
  //   storyData.asset = assetBuilder(storyData.asset);
  // }
  
  dispatch({
    type: ACTION_TYPES.BUILDER_STORY,
    payload: storyData
  });
}

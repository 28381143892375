import ACTION_TYPES from '../actionTypes.json';
import apiService from '../../services/apiService';
import assetBuilder from '../../services/assetBuilder';

export const LoadSuitesData = () => async(dispatch) => {
  const suitesData = await apiService.getSuitesData();

  const images = suitesData.map(o => {

    let w = Math.floor(o.width / 3);
    let h = Math.floor(o.height / 3);

    return {
      id: o.asset,
      alt: o.title,
      src: o.thumbnail ? `${assetBuilder(o.thumbnail)}?fit=cover&width=${o.width}&height=${o.height}&quality=100` : `${assetBuilder(o.asset)}?fit=cover&width=${w}&height=${h}&quality=100`,
      img: assetBuilder(o.asset),
      isVideo: o.asset ? o.asset.endsWith('mp4') : false, 
      isPDF: o.asset ? o.asset.endsWith('pdf') : false, 
      title: o.title,
      key: `${o.id}`,
      width: o.width,
      height: o.height,
      // thumb: `${assetBuilder(o.thumbnail)}?fit=cover&width=${o.width}&height=${o.height}&quality=80`,
      loading: 'lazy',
    }
  });

  dispatch({
    type: ACTION_TYPES.SUITES_ACTIONS.FETCH_DATA,
    payload: {
      images,
      activeImage: 0,
      expandedView: false,
      // isLoaded: true
    }
  });
}

export const SuitesSetActiveImage = (id) => {
  return {
    type: ACTION_TYPES.SUITES_ACTIONS.SET_ACTIVE_IMAGE,
    payload: id
  }
}

export const SuitesToggleExpandedView = (toggle) => {
  return {
    type: ACTION_TYPES.SUITES_ACTIONS.TOGGLE_EXPANDED_VIEW,
    payload: (toggle === true || toggle === false) ? toggle : null
  }
}

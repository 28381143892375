import axios from 'axios';
import _ from 'lodash';
import store from '../redux/store';
import config from '../configs/config.json';
import { AuthenticateRefresh } from '../features/authentication/authenticationActions';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

class apiService {
  static service = axios.create({
    baseURL: config.API_BaseUrl
  });

  static throwApiRequestFailedException = (response) => {
    throw new Error(`API Request failed (${response.data.errors ? response.data.errors[0].message : 'No Error Message'})`);
  }

  static _callService = async (url, method = "get", data = null, auth = true) => {
    // If auth is enabled, get auth header
    let headers = null;
    if (auth) {
      const authState = store.getState().authentication;
      if (!authState.isAuthenticated)
        throw new Error("User is not authenticated");
      headers = { 'Authorization': `Bearer ${authState.access_token}`,
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Methods' : 'GET,HEAD,OPTIONS,POST,PUT',
      'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept, x-client-key, x-client-token, x-client-secret, Authorization'
       };
    }

    // Call service
    try {
      const response = await this.service.request({ method, url, headers, data });
      return response;
    } catch (err) {
      const response = err.response;
      if (response.status !== 401 || auth === false)
        this.throwApiRequestFailedException(response);
        
      // Refresh the API token
      const authState = store.getState().authentication;
      const credentials = await this.service.request({
        url: '/auth/refresh',
        method: 'post',
        data: { 'refresh_token': authState.refresh_token }
      })

      // If failed, throw error
      if (credentials.status !== 200)
        this.throwApiRequestFailedException(response);

      // Update credentials 
      store.dispatch(AuthenticateRefresh(credentials.data.data));

      // Throw request with the new credentials
      headers = { 'Authorization': `Bearer ${credentials.data.data.access_token}` };
      try {
        const resp = await this.service.request({ method, url, headers, data });
        return resp;
      } catch (err) {
        this.throwApiRequestFailedException(response);
      }
    }
  };

  // API Calls
  // auth/login
  static authenticate = async () => {
    const data = {
      "email": "admin@trisev.com",
      "password": "remington"
    }
    const results = await this._callService('/auth/login', 'post', data, false);
    return results.data.data;
  };

  // overview
  static getOverviewData = _.memoize(async () => {
    const results = await this._callService('/overview/', 'get', null, false);
    console.log('Fetching Overview Data...');
    console.log(results.data);
    return results.data;
  });

  // project
  static getProjectData = async () => {
    const results = await this._callService('/project/', 'get', null, false);
    console.log('Fetching Project Data...');
    console.log(results.data);
    return results.data;
  }

  // amenities
  static getAmenitiesData = async () => {
    const results = await this._callService('/amenities/', 'get', null, false);
    console.log('Fetching Amenities Data...');
    console.log(results.data);
    return results.data;
  }

  // suites
  static getSuitesData = async () => {
    const results = await this._callService('/suites/', 'get', null, false);
    console.log('Fetching Suites Data...');
    console.log(results.data);
    return results.data;
  };

  // neighbourhood
  static getNeighbourhoodData = _.memoize(async () => {
    const results = await this._callService('/neighbourhood/', 'get', null, false);
    console.log('Fetching Neighbourhood Data...');
    console.log(results.data);
    return results.data;
  });

  // story
  static getStoryData = _.memoize(async () => {
    const results = await this._callService('/story', 'get', null, false);
    console.log('Fetching Story Data...');
    console.log(results.data);
    return results.data;
  });

  // floorplans
  static getFloorplansData = _.memoize(async () => {
    const results = await this._callService('/floorplans', 'get', null, false);
    console.log('Fetching Floorplans Data...');
    console.log(results.data);
    return results.data;
  });

    // floorplans
    static getFilteredFloorplansData = _.memoize(async (props) => {
      console.log('getFloorplansData');
      console.log(`/floorplans/?filter[category][_eq]=${props}`);
      const results = await this._callService(`/floorplans/?filter[category][_eq]=${props}`, 'get', null, false);
      console.log('Fetching Floorplans Data...');
      console.log(results.data);
      return results.data;
    });

  // screensaver
  static getScreensaverData = async () => {
    const results = await this._callService('/screensaver/', 'get', null, false);
    console.log('Fetching Screensaver Data...');
    console.log(results.data);
    return results.data;
  };

  // // custom/units
  // static getUnits = _.memoize(async () => {
  //   const results = await this._callService('/unit/');
  //   return results.data;
  // });

  // // items/areas 
  // static getFloors = _.memoize(async () => {
  //   const results = await this._callService('/items/areas');
  //   return results.data.data;
  // });

  // // custom/locations
  // static getLocations = _.memoize(async () => {
  //   const results = await this._callService('/locations');
  //   return results.data;
  // })

  // // custom/gallery
  // static getGallery = _.memoize(async () => {
  //   const results = await this._callService('/gallery/');
  //   return results.data;
  // });

  // // custom/marketing/
  // static getMarketingMedia = _.memoize(async () => {
  //   const results = await this._callService('/marketing/');
  //   return results.data;
  // });

  // // custom/balconyviews
  // static getBalconyViews = _.memoize(async () => {
  //   const results = await this._callService('/balconyviews');
  //   return results.data;
  // });

  // // custom/virtualtour
  // static getVirtualTours = _.memoize(async () => {
  //   const results = await this._callService('/virtualtour');
  //   return results.data;
  // });



  // POST: /custom/email/:email
  static postFavouritesList = async (payload, email, onComplete) => {
    console.log('sending email to: '+email);
    const resp = await this.service.request(
      {
        method: 'POST',
        url: `/email/${email}`,
        data: payload
      }
    )
    onComplete(resp.status);
    console.log('email sent successfully to '+email+ ' status: '+resp.status);
    // console.log(payload);
  }

  // POST: /custom/help
  // static postHelp = async (payload, onComplete, onFail) => {
  //   try{
  //     await this.service.request(
  //       {
  //         method: 'POST',
  //         url: '/help',
  //         data: payload
  //       }
  //     )
  //     onComplete();
  //   } catch {
  //     onFail();
  //   }
  // }
}

export default apiService;
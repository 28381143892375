import ACTION_TYPES from '../actionTypes.json';
import config from '../../configs/config.json';
import apiService from '../../services/apiService';
import history from '../../history/historyService'

export const AuthenticateUser = (email) => async (dispatch) => {
  // Call authentication service
  dispatch({ type: ACTION_TYPES.AUTHENTICATION.IN_PROGRESS })
  try {
    const data = await apiService.authenticate(email);
    data.isAuthenticated = true;
    data.email = email;
    window.localStorage.setItem(config.CacheKey_Email, email)
    dispatch({ type: ACTION_TYPES.AUTHENTICATION.AUTH_SUCCESS, payload: data });
  }
  catch (err) {
    console.error(err.message);
    window.localStorage.removeItem(config.CacheKey_Email);
    dispatch({ type: ACTION_TYPES.AUTHENTICATION.AUTH_FAILED });
    await history.push('/');
  }
}

export const AuthenticateLogOut = () => async (dispatch) => {
  window.localStorage.removeItem(config.CacheKey_Email);
  await history.push('/');
  dispatch({ type: ACTION_TYPES.AUTHENTICATION.LOG_OUT });
}

export const AuthenticateRefresh = (data) => {
  return {
    type: ACTION_TYPES.AUTHENTICATION.AUTH_REFRESH,
    payload: data
  }
}

export default AuthenticateUser;
import ACTION_TYPES from '../actionTypes.json';
import assetBuilder from '../../services/assetBuilder';

const defaultObject = {
  images: [],
  activeImage: 0,
  expandedView: false,
  isLoaded: false
  // id: 0,
  // status: "",
  // sort: 0,
  // title: "",
  // asset: ""
}

const overviewReducer = (current = defaultObject, { type, payload }) => {

  const clampIndex = (overview, index) => Math.min(Math.max(index, 0), overview.length - 1);
  
  // Get Data
  if (type === ACTION_TYPES.OVERVIEW_ACTIONS.FETCH_DATA) {
    return { ...payload, isLoaded: true }
  // if (type === ACTION_TYPES.OVERVIEW_ACTIONS_FETCH_DATA) {
  //   const { id, status, sort, title, asset } = payload;
  //   return { ...ov, id, status, sort, title, asset }
  // }
  }

  // Set Active Image
  if (type === ACTION_TYPES.OVERVIEW_ACTIONS.SET_ACTIVE_IMAGE) {
    const activeImage = clampIndex(current.activeGallery, payload);
    return { ...current, activeImage }
  }

  // Set Toggle Expanded View
  if (type === ACTION_TYPES.OVERVIEW_ACTIONS.TOGGLE_EXPANDED_VIEW) {
    const expandedView = payload ?? !current.expandedView;
    return { ...current, expandedView }
  }
  
  return current;
}

export default overviewReducer;
// const setDocumentStyleVariable = (name, payload) => document.documentElement.style.setProperty(`--color-${name}`, payload[name]);
// const setDocumentAssetVariable = (name, payload) => { 
//   document.documentElement.style.setProperty(`--asset-${name}`, `url(${assetBuilder(payload[name])})`)
//   console.log(`--asset-${name}`, `url(${assetBuilder(payload[name])})`);
//   console.log('payload: ');
//   console.log(payload[name]);
// }

// const overviewReducer = (current=defaultObject, {type, payload}) => {
//   if (type === ACTION_TYPES.LOAD_OVERVIEW_DATA) {
//     // Set document colors
//     console.log('overviewReducer:');
//     console.log(payload);
//     // setDocumentStyleVariable('action', payload);
//     // setDocumentStyleVariable('background_colour', payload);
//     // setDocumentStyleVariable('on_action', payload);
//     // setDocumentStyleVariable('on_action_active', payload);
//     // setDocumentStyleVariable('on_primary', payload);
//     // setDocumentStyleVariable('on_primary_active', payload);
//     // setDocumentStyleVariable('on_secondary', payload);
//     // setDocumentStyleVariable('on_secondary_active', payload);
//     // setDocumentStyleVariable('on_surface', payload);
//     // setDocumentStyleVariable('primary', payload);
//     // setDocumentStyleVariable('secondary', payload);
//     // setDocumentStyleVariable('surface', payload);
//     // Set document images
//     // setDocumentAssetVariable('background_image', payload);
//     // setDocumentAssetVariable('logo', payload);
//     return {...payload, isLoaded: true}
//   }
//   return current;
// }


import React from 'react';
import { connect } from 'react-redux';
import { BookmarkAdd, BookmarkRemove } from '../../redux/actions/bookmarksActions';
import A from './A';

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CloseIcon from '@mui/icons-material/Close';

class Bookmark extends React.Component {
  constructor(props) {
    super(props);
    this.parentRef = React.createRef();
    this.state = { fireAnim: false }
  }

  componentDidUpdate() {
    if (this.state.fireAnim)
      this.activeTimeout = setTimeout(() => this.setState({fireAnim: false}), 1000);
  }

  onBookmarkClick = () => {
    const { type, item } = this.props;
    // console.log('onBookmarkClick');
    // console.log(this.props);

    if (this.isBookmarkActive()){
      // console.log('bookmark type:');
      // console.log(type);
      // console.log('item type:');
      // console.log(item);
      this.props.BookmarkRemove(type, [item]);
      this.setState({fireAnim: false});
    }
    else{
      this.props.BookmarkAdd(type, [item]);
      // console.log('bookmarkadd type:');
      // console.log(type);
      // console.log('itemadd type:');
      // console.log(item);
      this.setState({fireAnim: true});
      clearTimeout(this.activeTimeout);
    }
  }

  isBookmarkActive = () => {
    const { type, item, bookmarks } = this.props;
    // console.log('is bookmark id active: '+item.id + ': ' + bookmarks[type].some(b => b.id === item.id));

    return bookmarks[type].some(b => b.id === item.id);
  }

  
  // isBookmarkActive = () => {
  //   {console.log(this.props)}
  //   if (this.props != undefined){
  //     const { type, item, bookmarks } = this.props;
  //     {console.log(type)}
  //     if (bookmarks[type] != undefined)
  //       return bookmarks[type].some(b => b.id === item.id);
  //     else
  //       return false;
  //   }else{
  //     return false;
  //   }
  // }

  generateAnimElement = () => {
    const isActive = this.isBookmarkActive();
    if (this.state.fireAnim) {
      const bounds = this.parentRef.current ? this.parentRef.current.getBoundingClientRect() : {right: 0, top: 0};
      const animStyle = {right: window.innerWidth - bounds.right, top: bounds.top};
      const animClass = `icon-bookmark-active bookmark-animation ${isActive ? 'zoom' : ''}`
      return <span className={animClass} style={animStyle} />
    }
    return null
  }

  render() {
    const isActive = this.isBookmarkActive();
    const className = isActive ? "icon-bookmark-active" : "icon-bookmark";
    return (
      <A onClick={this.onBookmarkClick} className="bookmark-button" ref={this.parentRef}>
        <span className={className} />
        {this.generateAnimElement()}
        
        { isActive ? 
          this.props.delete ? <CloseIcon/> : <BookmarkIcon className={'shadow'}/>
        : 
          <BookmarkBorderIcon className={'shadow'}/>
        }
      </A>
    )
  }
}

const mapStateToProps = state => { return { bookmarks: state.bookmarks } };
export default connect(mapStateToProps, { BookmarkAdd, BookmarkRemove })(Bookmark);
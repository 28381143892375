import React, {useEffect, Suspense} from 'react';
import Box from '@mui/material/Box';
import { loadProjectData } from '../redux/actions/projectActions';
import { connect } from 'react-redux';
import assetBuilder from '../services/assetBuilder';
import AuthenticateUser from "../redux/actions/authenticationActions";

const MainPage = (props) => {

  useEffect(() => {
    props.AuthenticateUser('admin@trisev.com');
  }, []);

  return (
      <div className="main">
          {/* <Box className="logo" sx={{ width: 256, height: 256 }} /> */}
      </div>
  )
}

const mapStateToProps = (state) => { 
  if (state.project.isLoaded) {
    console.log(state);
    document.title = state.project.title;
    document.documentElement.style.setProperty('--asset-background_image', `url(${assetBuilder(state.project.background_image)})`);
  }
  return { 
    project: state.project,
  }
}

export default connect(mapStateToProps, { AuthenticateUser, loadProjectData })(MainPage);
  
import React, { useEffect } from 'react';
import Gallery from './Gallery/Gallery';

import { LoadSuitesData } from '../redux/actions/suitesActions';
import { connect } from 'react-redux';
import { withRouter } from "../redux/actions/withRouter";

const Suites = (props) => {

    useEffect(() => {
        props.LoadSuitesData();
        document.title = props.title + ' - Suites';
        document.documentElement.style.setProperty('--asset-background_image', 'none')
    }, []);

    return (
      <React.Fragment>
        {props.suites.images.length > 0 ? (
          <Gallery images={props.suites.images} type={'renderings'}/>
        ) :  null }
      </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return { 
      suites: state.suites 
    }
}

export default withRouter(connect(mapStateToProps, { LoadSuitesData })(Suites));
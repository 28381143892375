import React, { useEffect } from 'react';
import Gallery from './Gallery/Gallery';

import { NeighbourhoodFetch } from '../redux/actions/neighbourhoodActions';
import { connect } from 'react-redux';
import { withRouter } from "../redux/actions/withRouter";

const Neighbourhood = (props) => {

  useEffect(() => {
      props.NeighbourhoodFetch();
      document.documentElement.style.setProperty(`--asset-${'background_image'}`, 'none');
      document.title = props.title + ' - Neighbourhood';
  }, []);

  return (
    <React.Fragment>
      {props.neighbourhood.images.length > 0 ?
        <Gallery images={props.neighbourhood.images} type={'renderings'}/>
      :  null
      }
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return { 
    neighbourhood: state.neighbourhood 
  }
}

export default withRouter(connect(mapStateToProps, { NeighbourhoodFetch })(Neighbourhood));